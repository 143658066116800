<div
  class="toolbar-videocall"
  [class.isMobile]="isMobile"
>
  <div class="toolbar">
    <div class="toolbar-list_main">
      <div
        class="toolbar-btn toolbar-btn-end"
        (click)="leaveSession()"
        #tooltipLeaveBtn
        (mouseover)="createTooltip(tooltipLeaveContent, tooltipLeaveBtn)"
        (mouseleave)="removeTooltip()"
      ><img src="assets/img/svg/group-lesson-next/toolbar-end.svg" /></div>
      <div
        *ngIf="canAudio && (!isSpeech || isOwner)"
        class="toolbar-btn toolbar-btn-mic"
        (click)="audioStatusChanged()"
        #tooltipAudioBtn
        (mouseover)="createTooltip(tooltipAudioContent, tooltipAudioBtn)"
        (mouseleave)="removeTooltip()"
      >
        <ng-container *ngIf="publisherStateChangingValue === true || lazyIniting === true">
          <app-ellipsis-loader></app-ellipsis-loader>
        </ng-container>

        <img
          *ngIf="!audioEnabled && publisherStateChangingValue !== true && !lazyIniting"
          src="assets/img/svg/group-lesson-next/toolbar-mic-off.svg"
          alt="audioDisabled"
        />
        <img
          *ngIf="audioEnabled && publisherStateChangingValue !== true && !lazyIniting"
          src="assets/img/svg/group-lesson-next/toolbar-mic-on.svg"
          alt="audioEnabled"
        />
      </div>

      <ng-container *ngIf="!(canAudio && (!isSpeech || isOwner))">
        <div
          class="toolbar-btn toolbar-btn-mic disabled"
          (click)="openAudioInfoPopup()"
          type="button"
          cdkOverlayOrigin
          #trigger="cdkOverlayOrigin"
          #tooltipAudioBtn
          (mouseover)="createTooltip(tooltipAudioContent, tooltipAudioBtn)"
          (mouseleave)="removeTooltip()"
        >
          <img
            src="assets/img/svg/group-lesson-next/toolbar-mic-off-red.svg"
            alt="audioDisabled"
          />
        </div>
        <ng-template
          cdkConnectedOverlay
          [cdkConnectedOverlayOrigin]="trigger"
          [cdkConnectedOverlayOpen]="isAudioInfoPopupOpen"
          [cdkConnectedOverlayPositions]="infoPopupPositionsAudio"
          [cdkConnectedOverlayHasBackdrop]="true"
          [cdkConnectedOverlayBackdropClass]="'cdk-drop-down-back'"
          (backdropClick)="closeInfoPopup()"
        >
          <ezteach-info-popup
            [hasCloseBtn]="true"
            (onCloseBtnClick)="closeInfoPopup()"
          >
            <div title>{{ 'Микрофон' | transloco }}</div>
            <div content>{{ 'Использование микрофона запрещено владельцем или модератором занятия' | transloco }}</div>
            <div buttons>
              <ezteach-btn
                (click)="closeInfoPopup()"
                [type]="buttonType.infoPopupFilled"
                [width]="'129px'"
              >
                <div content>{{ 'Закрыть' | transloco }}</div>
              </ezteach-btn>
            </div>
          </ezteach-info-popup>
        </ng-template>
      </ng-container>

      <div
        *ngIf="canVideo && (!isSpeech || isOwner)"
        class="toolbar-btn toolbar-btn-cam"
        (click)="videoStatusChanged()"
        #tooltipVideoBtn
        (mouseover)="createTooltip(tooltipVideoContent, tooltipVideoBtn)"
        (mouseleave)="removeTooltip()"
      >
        <ng-container *ngIf="publisherStateChangingValue === true || lazyIniting === true">
          <app-ellipsis-loader></app-ellipsis-loader>
        </ng-container>

        <img
          *ngIf="!videoEnabled && publisherStateChangingValue !== true && !lazyIniting"
          src="assets/img/svg/group-lesson-next/toolbar-cam-off.svg"
          alt="videoDisabled"
        />
        <img
          *ngIf="videoEnabled && publisherStateChangingValue !== true && !lazyIniting"
          src="assets/img/svg/group-lesson-next/toolbar-cam-on.svg"
          alt="videoEnabled"
        />
      </div>

      <ng-container *ngIf="!(canVideo && (!isSpeech || isOwner))">
        <div
          class="toolbar-btn toolbar-btn-cam disabled"
          (click)="openVideoInfoPopup()"
          type="button"
          cdkOverlayOrigin
          #trigger="cdkOverlayOrigin"
          #tooltipVideoBtn
          (mouseover)="createTooltip(tooltipVideoContent, tooltipVideoBtn)"
          (mouseleave)="removeTooltip()"
        >
          <img
            src="assets/img/svg/group-lesson-next/toolbar-cam-off-red.svg"
            alt="videoDisabled"
          />
        </div>
        <ng-template
          cdkConnectedOverlay
          [cdkConnectedOverlayOrigin]="trigger"
          [cdkConnectedOverlayOpen]="isVideoInfoPopupOpen"
          [cdkConnectedOverlayPositions]="infoPopupPositionsVideo"
          [cdkConnectedOverlayHasBackdrop]="true"
          [cdkConnectedOverlayBackdropClass]="'cdk-drop-down-back'"
          (backdropClick)="closeInfoPopup()"
        >
          <ezteach-info-popup
            [hasCloseBtn]="true"
            (onCloseBtnClick)="closeInfoPopup()"
          >
            <div title>{{ 'Камера' | transloco }} </div>
            <div content>{{ 'Использование камеры запрещено владельцем или модератором занятия' | transloco }}</div>
            <div buttons>
              <ezteach-btn
                (click)="closeInfoPopup()"
                [type]="buttonType.infoPopupFilled"
                [width]="'129px'"
              >
                <div content>{{ 'Закрыть' | transloco }} </div>
              </ezteach-btn>
            </div>
          </ezteach-info-popup>
        </ng-template>
      </ng-container>

      <div
        class="toolbar-btn toolbar-btn-chat"
        [ngClass]="groupLessonService.chatOpen$.value ? '--active' : ''"
        (click)="chatOpenToggle()"
        #tooltipChatBtn
        (mouseover)="!isMobile && createTooltip(tooltipChatContent, tooltipChatBtn)"
        (mouseleave)="!isMobile && removeTooltip()"
      ><img src="assets/img/svg/group-lesson-next/toolbar-message.svg" />

        <span
          *ngIf="!chatOpen && isHaveUnreadMessages && unreadMessageCounter"
          class="toolbar-btn-notification"
        >{{unreadMessageCounter}}</span>
      </div>
      <div
        class="toolbar-btn toolbar-btn-group"
        [ngClass]="groupLessonService.parcipantsOpen$.value ? '--active' : ''"
        #participantButton
        (click)="groupOpenToggle()"
        (mouseover)="!isMobile && createTooltip(tooltipParticipantContent, participantButton)"
        (mouseleave)="!isMobile && removeTooltip()"
      >
        <img src="assets/img/svg/group-lesson-next/toolbar-group.svg" />
        <span
          *ngIf="usersCount"
          class="toolbar-btn-notification"
        >{{ usersCount }}</span>
      </div>

      <ng-container *ngIf="!isMobile">
        <button
          *ngIf="isOwner"
          class="toolbar-btn"
          (click)="toggleWhiteboard()"
          #tooltipWhiteboardBtn
          (mouseover)="createTooltip(tooltipWhiteboardContent, tooltipWhiteboardBtn)"
          (mouseleave)="removeTooltip()"
        >
          <img
            src="/assets/img/svg/group-lesson/show-board.svg"
            width="30px"
            height="30px"
            alt="moderate-whiteboard"
          />
        </button>

        <div
          *ngIf="canShare && !isANDROID && !isAppleMobile && (!isSpeech || isOwner)"
          class="toolbar-btn"
          #tooltipShareScreenBtn
          (mouseover)="createTooltip(tooltipShareScreenContent, tooltipShareScreenBtn)"
          (mouseleave)="removeTooltip()"
        >
          <img
            *ngIf="!shareActive && !shareDisabled"
            class="start"
            src="/assets/img/svg/icon-share-on_white.svg"
            (click)="startShare()"
            alt="shareDisabled"
          />
          <img
            *ngIf="shareActive && !shareDisabled"
            class="stop"
            src="/assets/img/svg/icon-share-off_white.svg"
            (click)="stopShare()"
            alt="shareEnabled"
          />

          <img
            *ngIf="shareDisabled"
            class="disabled"
            type="button"
            src="/assets/img/svg/icon-share-on_white.svg"
            alt="shareDisabled"
          />
        </div>
      </ng-container>


      <ng-container *ngIf="!canShare && isOwner">
        <div
          class="toolbar-btn"
          (click)="openShareInfoPopup()"
          type="button"
          cdkOverlayOrigin
          #trigger="cdkOverlayOrigin"
          #tooltipShareScreenBtn
          (mouseover)="createTooltip(tooltipShareScreenContent, tooltipShareScreenBtn)"
          (mouseleave)="removeTooltip()"
        >
          <img
            src="/assets/img/svg/icon-share-on_white.svg"
            alt="shareDisabled"
          />
        </div>
        <ng-template
          cdkConnectedOverlay
          [cdkConnectedOverlayOrigin]="trigger"
          [cdkConnectedOverlayOpen]="isShareInfoPopupOpen"
          [cdkConnectedOverlayPositions]="infoPopupPositionsShareScreen"
          [cdkConnectedOverlayHasBackdrop]="true"
          [cdkConnectedOverlayBackdropClass]="'cdk-drop-down-back'"
          (backdropClick)="closeInfoPopup()"
        >
          <ezteach-info-popup
            [hasCloseBtn]="true"
            (onCloseBtnClick)="closeInfoPopup()"
          >
            <div title>{{ 'Демонстрация экрана' | transloco }}</div>
            <div content>{{ 'Использование демонстрации экрана запрещено владельцем или модератором занятия' | transloco
              }}
            </div>
            <div buttons>
              <ezteach-btn
                (click)="closeInfoPopup()"
                [type]="buttonType.infoPopupFilled"
                [width]="'129px'"
              >
                <div content>Закрыть</div>
              </ezteach-btn>
            </div>
          </ezteach-info-popup>
        </ng-template>
      </ng-container>

      <div
        #addHomeworkToolbar
        (click)="addHomework()"
        class="toolbar-btn"
        *ngIf="isOwner && !isMobile"
        #tooltipAddHomeworkBtn
        (mouseover)="createTooltip(tooltipAddHomework, tooltipAddHomeworkBtn)"
        (mouseleave)="removeTooltip()"
      >
        <img src="assets/img/svg/group-lesson-next/toolbar-dz.svg" />
      </div>

      <ng-container *ngIf="isOwner || !isSpeech">
        <div
          class="toolbar-btn"
          (click)="openMenu()"
          [ngClass]="{ opened: isMenuOpen }"
          type="button"
          cdkOverlayOrigin
          #trigger="cdkOverlayOrigin"
          #tooltipSettingsBtn
          (mouseover)="createTooltip(tooltipSettingsContent, tooltipSettingsBtn)"
          (mouseleave)="removeTooltip()"
        >
          <img
            src="/assets/img/svg/icon-menu_white.svg"
            alt="moderate-menu"
            *ngIf="isMobile"
          />
          <img
            src="assets/img/svg/group-lesson-next/toolbar-setting.svg"
            alt="moderate-menu"
            *ngIf="!isMobile"
          />
        </div>
        <ng-template
          cdkConnectedOverlay
          [cdkConnectedOverlayOrigin]="trigger"
          [cdkConnectedOverlayOpen]="isMenuOpen"
          [cdkConnectedOverlayPositions]="infoPopupPositionsDropDown"
          [cdkConnectedOverlayHasBackdrop]="true"
          [cdkConnectedOverlayBackdropClass]="'cdk-drop-down-back'"
          (backdropClick)="closeMenu()"
        >
          <ezteach-group-lesson-moderate-dropdown
            [videoEnabled]="videoEnabled"
            [audioEnabled]="audioEnabled"
            [canAudio]="canAudio"
            [canVideo]="canVideo"
            [canShare]="canShare"
            [lessonLink]="lessonLink"
            [inviteLink]="inviteLink"
            [isOwner]="isOwner"
            (onShareStart)="openShareInfoPopup()"
            (onShareStop)="stopShare()"
            [shareActive]="shareActive"
            (onMuteChanged)="onMuteChangedClick($event)"
            (onVideoStatusChanged)="onVideoStatusChangedClick($event)"
            (onHandToggleClicked)="handToggle()"
          ></ezteach-group-lesson-moderate-dropdown>
        </ng-template>
      </ng-container>
    </div>

    <div
      class="toolbar-btn toolbar-btn-hand"
      (click)="handToggle()"
    >
      <img
        *ngIf="!groupLessonReactionService.currentUserHandState$.value && !isSpeech"
        src="assets/img/svg/group-lesson-next/toolbar-btn-hand.svg"
      />
      <img
        *ngIf="groupLessonReactionService.currentUserHandState$.value && !isSpeech"
        src="assets/img/svg/group-lesson-next/toolbar-btn-hand-active.svg"
      />

      <div class="reaction-popup">
        <!--div class="reaction">
          <div
            class="reaction-button reaction-button_hand-up"
            (click)="handToggle()"
          ><img src="assets/img/temp/hand-up.png" /></div>
          <div class="reaction-button reaction-button_like"><img src="assets/img/temp/like.png" /></div>
          <div class="reaction-button reaction-button_fire"><img src="assets/img/temp/fire.png" /></div>
          <div class="reaction-button reaction-button_heart"><img src="assets/img/temp/heart.png" /></div>
          <div class="reaction-button reaction-button_dizlike"><img src="assets/img/temp/dizlike.png" /></div>
          <div class="reaction-button reaction-button_smile_with_heart"><img
              src="assets/img/temp/smile_with_heart.png" />
          </div>
          <div class="reaction-button reaction-button_smile_with_hand"><img src="assets/img/temp/smile_with_hand.png" />
          </div>
          <div class="reaction-button reaction-button_hands"><img src="assets/img/temp/hands.png" /></div>
        </div-->
      </div>
    </div>

  </div>
</div>