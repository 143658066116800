import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UserService } from "@ezteach/_services/user.service";
import { LangStoreService } from '@ezteach/shared/services/lang-store.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { FileDocumentIEnumerableApiResponse, UserProfile } from '../api/models';
import { loadProfile, selectPersonalTitle, selectProfile, updateUserAvatar } from './+state';
import { ProfileService } from './profile.service';
import { ProfileLocalStorageService } from './services/profile-localstorage.service';
//import tippy, { Instance, Props } from 'tippy.js';

@UntilDestroy()
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  user: any;
  profile: UserProfile;
  title: string;
  title$: Observable<string>;
  lang: string;
  scale: any = 1;//Масштабирование аватара
  offsetX: number = 0;//Сдвиг аватара по X;
  offsetY: number = 0;//Сдвиг аватара по Y;
  environment = environment;
  avatarSettingsOpen = false;
  positions = [
    new ConnectionPositionPair({ originX: 'start', originY: 'top' }, { overlayX: 'start', overlayY: 'top' }, 0, -6),
    new ConnectionPositionPair(
      { originX: 'start', originY: 'top' },
      { overlayX: 'start', overlayY: 'bottom' }),
    new ConnectionPositionPair({ originX: 'start', originY: 'center' }, { overlayX: 'start', overlayY: 'center' }, 0, -6),
    new ConnectionPositionPair({ originX: 'end', originY: 'center' }, { overlayX: 'end', overlayY: 'center' }, 0, -6),
  ];

  constructor(

    private profileService: ProfileService,
    private profileLocalStorageService: ProfileLocalStorageService,
    private store: Store,
    private cd: ChangeDetectorRef,
    private userService: UserService,
    private langStoreService: LangStoreService
  ) { }

  ngOnInit() {
    this.environment = environment;
    this.user = this.userService.userData$.value;
    this.profileLocalStorageService.localStorageUser$.next(this.user);
    this.profileService.user.next(this.user);

    this.store.dispatch(loadProfile());

    this.lang = this.langStoreService.getCurrentLang();

    this.store
      .pipe(
        untilDestroyed(this),
        select(selectPersonalTitle),
        tap(title => {
          this.title = title;
          this.cd.detectChanges();
        }),
      )
      .subscribe();

    this.store
      .pipe(
        untilDestroyed(this),
        select(selectProfile),
        filter(profile => !!profile),
        tap(profile => {
          this.profile = profile;
          if (this.profile?.userAvatar?.avatarFileName) {//Получаем данные о обрезке аватара из урла файла

            var fileUrl = new URL("http://" + profile.userAvatar.avatarFileName);
            this.scale = fileUrl.searchParams.get("scale") ? +fileUrl.searchParams.get("scale") : 1;
            this.offsetX = fileUrl.searchParams.get("offsetX") ? Math.round(+fileUrl.searchParams.get("offsetX")) : 0;
            this.offsetY = fileUrl.searchParams.get("offsetY") ? +Math.round(+fileUrl.searchParams.get("offsetY")) : 0;
          }
          this.cd.detectChanges();
        }),
      )
      .subscribe();

    this.userService.userData$
      .pipe(
        untilDestroyed(this),
        tap(data => {
          this.user = data;
          this.profileLocalStorageService.localStorageUser$.next(this.user);
          this.profileService.user.next(this.user);
        })
      ).subscribe();
  }



  saveAvatarSettings(event) {//Сохранение центрирования аватара
    if (this.profile?.userAvatar?.avatarFileName) {
      var pr = { ...this.profile };
      pr.userAvatar = {
        ...this.profile.userAvatar,
        avatarFileName: this.profile?.userAvatar?.avatarFileName.split("?")[0] + "?scale=" + event.scale + "&offsetX=" + event.offsetX + "&offsetY=" + event.offsetY,
      };
      this.profileService.setProfile(pr).subscribe();
      this.avatarSettingsOpen = false;
      this.store.dispatch(updateUserAvatar(pr.userAvatar));
    }
  }

  avatarChange(event) {
    if (event.target.files && event.target.files[0]) {
      this.profileService
        .uploadUserAvatar({
          file: event.target.files[0]
        })
        .subscribe((request: FileDocumentIEnumerableApiResponse) => {
          if (request.data) {
            const userAvatar = {
              ...this.profile.userAvatar,
              avatarFileName: request.data[0].fileName
            };
            this.store.dispatch(updateUserAvatar(null));
            this.cd.detectChanges();
            this.store.dispatch(updateUserAvatar(userAvatar));
            this.cd.detectChanges();
          }
        });
    }
  }
}
